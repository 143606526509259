<template>
  <div class="p-root">
    <div class="full-width artists-banner">
      <img :src="icons.artists_banner" class="full-width" />
      <img :src="icons.artists_huxian" class="full-width artists-huxian" />
    </div>

    <div class="full-width column-center" style="padding: 50px 0">
      <div class="artists-content-width column">
        <span class="artists-title" style="align-self: center"
          >“哈罗~合作者”</span
        >
        <span class="artists-label" style="margin-top: 50px"
          >联创国际艺术家：Malgorzata PASZKO 马尔戈扎塔·帕斯科</span
        >

        <p class="artists-content">
          生活在诺曼底的印象派画家与雕刻家，罗马奖（梅蒂奇别墅）获得者，曾多次于2016-2017在中国居住并展出。其在法国、比利时、德国、希腊、波兰以及黎巴嫩等国举办了许多展览。部分作品现展览于法国当代艺术基金会、毕加索博物馆、
          法国国家图书馆、波兰托伦大学、萨拉热窝国家美术馆、卢森堡杜德朗日市政府……
        </p>

        <span
          class="artists-link"
          style="align-self: flex-end"
          @click="navigateTo('group35')"
          >点此查看艺术家观点</span
        >

        <img
          class="artists-content-width"
          style="margin-top: 20px"
          :src="icons.artists_malgorzata_paszko_image"
        />

        <video
          style="width: 800px; margin-top: 20px"
          :src="icons.artists_malgorzata_paszko_video"
          controls
        ></video>

        <span class="artists-desc" style="align-self: center"
          >Malgorzata PASZKO 马尔戈扎塔·帕斯科对“中国合作者说的一句话”</span
        >
      </div>

      <div class="artists-content-width column">
        <span class="artists-label" style="margin-top: 100px"
          >联创国际艺术家：Christophe RONEL 克里斯托夫·罗内尔</span
        >

        <p class="artists-content">
          法国形象艺术派画家，在过去的三十年里，在法国以及其他十几个国家的画廊、文化中心以及博物馆举办过
          80
          多次个人展览，比如在巴黎、里尔、蒙彼利埃、尼斯、圣马洛、布鲁塞尔、卡萨布兰卡、马拉喀什、苏塞、汉诺威、棕榈滩、新加坡、东京，以及中国国家博物馆与天津博物馆。其作品现藏于法国、比利时、荷兰、德国、奥地利、瑞士、意大利、摩洛哥、黎巴嫩、美国、加拿大、巴西、澳大利亚、新加坡、日本和阿拉伯联合酋长国的各种公共和私人收藏中。
        </p>

        <span
          class="artists-link"
          style="align-self: flex-end"
          @click="navigateTo('group67')"
          >点此查看艺术家观点</span
        >

        <img
          class="artists-content-width"
          style="margin-top: 20px"
          :src="icons.artists_christophe_ronel_image"
        />

        <video
          style="width: 800px; margin-top: 20px"
          :src="icons.artists_christophe_ronel_video"
          controls
        ></video>

        <span class="artists-desc" style="align-self: center"
          >Christophe RONEL 克里斯托夫·罗内尔对“中国合作者说的一句话”</span
        >
      </div>

      <div class="artists-content-width column">
        <span class="artists-label" style="margin-top: 100px"
          >联创国际艺术家：Emmanuelle RENARD 埃马纽埃尔·热娜徳</span
        >

        <p class="artists-content">
          画家、设计师、雕刻师、陶艺家，是当今法国舞台上的一位重要的具象艺术家，她自
          1986
          年以来一直在法国和其他国家举办画展：巴黎、汉堡、法兰克福、卡尔斯鲁厄、伦敦、布鲁塞尔、哥本哈根、日内瓦、巴塞尔、斯德哥尔摩、芝加哥、洛杉矶、贝鲁特......
          三十多年来，她吸引了众多法国和国外的收藏家。对于定义她的画作，我们可以称之为超现实表现主义，反之亦然。因为它是一种强烈、有力、极具表现力的画。在她的画作中，物体似乎被赋予了生命，有着古怪的人物，几乎不可能存在的场景以及极其纯粹的色彩。如果一定要给这种画作找到一个起源，我们会很乐意提及巴洛克艺术家，然后是戈雅、苏丁、夏加尔和雷贝罗尔。
        </p>

        <span
          class="artists-link"
          style="align-self: flex-end"
          @click="navigateTo('group89')"
          >点此查看艺术家观点</span
        >

        <img
          class="artists-content-width"
          style="margin-top: 20px"
          :src="icons.artists_emmanuelle_renard_image"
        />

        <video
          style="width: 800px; margin-top: 20px"
          :src="icons.artists_emmanuelle_renard_video"
          controls
        ></video>

        <span class="artists-desc" style="align-self: center"
          >Emmanuelle RENARD 埃马纽埃尔·热娜徳对“中国合作者说的一句话”</span
        >
      </div>

      <div class="artists-content-width column">
        <span class="artists-label" style="margin-top: 100px"
          >联创国际艺术家：Sophie SAINRAPT 苏菲·圣拉</span
        >

        <p class="artists-content">
          画家和雕刻家，其作品深受文学启发，曾在法国，中国，比利时，意大利，韩国，日本，美国等国家举办过近150次展览。她同时在法国参议院工作，直到
          2016 年，她负责在橘园和卢森堡花园举办的当代艺术活动 ArtSénat
          长达十年之久。
        </p>

        <span
          class="artists-link"
          style="align-self: flex-end"
          @click="navigateTo('group12')"
          >点此查看艺术家观点</span
        >

        <img
          class="artists-content-width"
          style="margin-top: 20px"
          :src="icons.artists_sophie_sainrapt_image"
        />

        <video
          style="width: 800px; margin-top: 20px"
          :src="icons.artists_sophie_sainrapt_video"
          controls
        ></video>

        <span class="artists-desc" style="align-self: center"
          >Sophie SAINRAPT 苏菲·圣拉对“中国合作者说的一句话”</span
        >
      </div>

      <div class="artists-content-width column">
        <span class="artists-label" style="margin-top: 100px"
          >联创国际艺术家：Augusto FOLDI 奥古斯都·弗乐迪</span
        >

        <p class="artists-content">
          画家，意大利匈牙利裔。60年代末，住在法国北部，实现了他的第一幅画。1975
          年定居巴黎，通过象征和隐喻对油画技术进行了一系列研究。1979
          年，在巴黎举办首次展览。随后，在法国举办了20余次画展。
        </p>

        <span
          class="artists-link"
          style="align-self: flex-end"
          @click="navigateTo('group14')"
          >点此查看艺术家观点</span
        >

        <img
          class="artists-content-width"
          style="margin-top: 20px"
          :src="icons.artists_augusto_foldi_image"
        />

        <video
          style="width: 800px; margin-top: 20px"
          :src="icons.artists_augusto_foldi_video"
          controls
        ></video>

        <span class="artists-desc" style="align-self: center"
          >Augusto FOLDI 奥古斯都·弗乐迪对“中国合作者说的一句话”</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  methods: {
    navigateTo(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style scoped>
.artists-banner {
  position: relative;
  display: block;
}
.artists-huxian {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.artists-title {
  font-size: 22px;
  color: #282828;
  letter-spacing: 0;
}
.artists-label {
  font-weight: bold;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
}
.artists-content {
  font-size: 16px;
  color: #282828;
  letter-spacing: 0;
  text-indent: 25px;
}
.artists-link {
  cursor: pointer;
  font-size: 16px;
  color: #282828;
  font-weight: bold;
  letter-spacing: 0;
  color: #154a80;
  text-decoration: underline;
}
.artists-desc {
  margin-top: 10px;
  font-size: 16px;
  color: #282828;
  font-weight: bold;
  letter-spacing: 0;
  color: #154a80;
}
.artists-content-width {
  width: 800px;
}
</style>